import $ from "jquery";
import "./gallery.css";

$(() => {
  $(".gallery__thumb").click(function() {
    const big = $(this)
      .closest(".js-gallery")
      .find(".gallery__big");

    const activeClass = $(this).attr("data-active-class");

    big
      .attr("src", $(this).attr("data-src"))
      .attr("srcset", $(this).attr("data-srcset"));

    $(this)
      .addClass(activeClass)
      .siblings()
      .removeClass(activeClass);
  });
});
