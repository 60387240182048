import Rails from "rails-ujs";
import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
// import "../utils/animate-number";

import "./index.css";
import "./form.css";
import "./contact-now.css";

import "../components/face/face";
import "../components/numerology/numerology";
import "../components/testimonials/testimonials";
import "../components/gallery/gallery";
import "../components/about/about";
import "../components/faq/faq";
import "../components/schools/schools";
import "../components/footer/footer";
import "../components/page/page";
import "../components/breadcrumbs/breadcrumbs";
import "../components/subscribe_now/subscribe_now";
import "../components/consultation/consultation";
import "../components/lead_form/lead_form";
import "../components/header/header";
import "../components/full_form/full_form";
import "../components/additional_info/additional_info";
import "../components/video_testimonial/video_testimonial";

Rails.start();
// eslint-ignore
new LazyLoad();
window.$ = $;
