import $ from "jquery";
import "./additional_info.css";

$(function() {
  $(".additional-info").each(function() {
    var that = this;
    var activeClass = "additional-info--active";

    $(that)
      .find(".additional-info__button")
      .click(function() {
        var html = $(that)
          .find(".additional-info__html")
          .html();
        $($(that).attr("data-render-to")).html(html);
        $(".additional-info").removeClass(activeClass);
        $(that).addClass(activeClass);

        return false;
      });
  });
});
