import $ from "jquery";

import "./page.css";

$(() => {
  $(".js-switch-planet").click(function jsSwitchPlanet() {
    const planet = $(this).attr("data-planet");

    $(".js-switch-planet").removeClass("form__button_primary");
    $(this).addClass("form__button_primary");

    $(`.active-planet[data-planet='${planet}']`)
      .addClass("active")
      .siblings()
      .removeClass("active");

    return false;
  });
});
