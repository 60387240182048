import "particles.js/particles.js";
import $ from "jquery";

// import VanillaScrollspy from "vanillajs-scrollspy";
// import { isDesktop } from "../../utils/device";
import "./face.css";
import "./logo.svg";
import "./bg.jpeg";
import "./bg2-sm.jpg";
import "./bg2-xs.jpg";

document.addEventListener("DOMContentLoaded", () => {
  $(".face__navbar-button").click(() => {
    $(".face__navbar-icon").toggleClass("face__navbar-icon_active");
    $(".face__navbar-wrapper").toggleClass("face__navbar-wrapper_active");
    $(".face__navbar").toggleClass("face__navbar_darky");
    $(".face__logo-menu-wrapper").toggleClass("face__logo-menu-wrapper_active");
  });

  const navbar = document.querySelector(".face__navbar");
  const navbarFaker = document.querySelector(".face__navbar-faker");
  if (navbar && navbarFaker) {
    const sticky = navbar.offsetTop;

    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        navbarFaker.style.height = `${navbar.clientHeight}px`;
        navbar.classList.add("face__navbar_sticky");
      } else {
        navbar.classList.remove("face__navbar_sticky");
        navbarFaker.style.height = 0;
      }
    };
  }

  // if (isDesktop()) {
  //   const menu = document.querySelector(".face__logo-menu");
  //   if (menu && menu.offsetTop > 0) {
  //     const scrollspy = new VanillaScrollspy(menu);
  //     scrollspy.init();
  //   }
  // }

  // if (isDesktop()) {
  //   $(".factoid [data-number]").each(function() {
  //     const start = this.getAttribute("data-start");
  //     $(this)
  //       .prop("number", Number(start) >= 0 ? Number(start) : 0)
  //       .animateNumber(
  //         {
  //           number: this.getAttribute("data-number")
  //         },
  //         800
  //       );
  //   });
  // }

  // if (document.getElementById("face__particles") && isDesktop() && false) {
  //   particlesJS("face__particles", {
  //     interactivity: {
  //       detect_on: "canvas",
  //       events: {
  //         onhover: {
  //           enable: false,
  //           mode: "repulse"
  //         },
  //         onclick: {
  //           enable: false,
  //           mode: "push"
  //         },
  //         resize: true
  //       },
  //       modes: {
  //         grab: {
  //           distance: 400,
  //           line_linked: {
  //             opacity: 1
  //           }
  //         },
  //         bubble: {
  //           distance: 400,
  //           size: 40,
  //           duration: 2,
  //           opacity: 8,
  //           speed: 3
  //         },
  //         repulse: {
  //           distance: 200
  //         },
  //         push: {
  //           particles_nb: 4
  //         },
  //         remove: {
  //           particles_nb: 2
  //         }
  //       }
  //     },
  //     particles: {
  //       number: {
  //         value: 100,
  //         density: {
  //           enable: true,
  //           value_area: 800
  //         }
  //       },
  //       color: {
  //         value: "#ffffff"
  //       },
  //       shape: {
  //         type: "circle",
  //         stroke: {
  //           width: 0,
  //           color: "#000000"
  //         },
  //         polygon: {
  //           nb_sides: 5
  //         }
  //       },
  //       opacity: {
  //         value: 0.4,
  //         random: false,
  //         anim: {
  //           enable: false,
  //           speed: 1,
  //           opacity_min: 0.1,
  //           sync: false
  //         }
  //       },
  //       size: {
  //         value: 3,
  //         random: true,
  //         anim: {
  //           enable: false,
  //           speed: 50,
  //           size_min: 0.1,
  //           sync: false
  //         }
  //       },
  //       line_linked: {
  //         enable: true,
  //         distance: 150,
  //         color: "#ffffff",
  //         opacity: 0.4,
  //         width: 1
  //       },
  //       move: {
  //         enable: true,
  //         speed: 2,
  //         direction: "none",
  //         random: false,
  //         straight: false,
  //         out_mode: "out",
  //         bounce: false,
  //         attract: {
  //           enable: false,
  //           rotateX: 600,
  //           rotateY: 1200
  //         }
  //       }
  //     },
  //     retina_detect: true
  //   });
  // }
});
