import $ from "jquery";
import "./schools.css";

const requireImage = require.context("./", true, /\.(png|jpg)$/);
requireImage.keys().forEach(requireImage);

document.addEventListener("DOMContentLoaded", () => {
  // $(".schools__button").each(function() {
  //   const src = $(this).attr("data-src");
  //   setTimeout(() => (new Image().src = src), 0);
  //
  //   $(this)
  //     .attr("data-srcset")
  //     .split(", ")
  //     .map(i => i.replace(/\s\d{1,5}\w$/, ""))
  //     .forEach(i => {
  //       setTimeout(() => (new Image().src = i), 0);
  //     });
  // });

  $(".schools__button").click(function() {
    const activeClass = $(this).attr("data-active-class");
    $(".schools__buttons .schools__button").removeClass(activeClass);
    $(this).addClass(activeClass);

    $(this)
      .closest(".js-schools")
      .find(".schools__background")
      .attr("srcset", $(this).attr("data-srcset"));

    $(".school-info").hide();
    $(`.school-info[data-key='${$(this).attr("data-key")}']`).show();
  });
});
