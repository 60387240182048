import $ from "jquery";
import "./numerology.css";

const requireImage = require.context("./", true, /\.(png|jpg)$/);
requireImage.keys().forEach(requireImage);

document.addEventListener("DOMContentLoaded", () => {
  // $(".js-gallery").each(function jsgallery() {
  //   const src = $(this)
  //     .find("img[src]")[0]
  //     .getAttribute("src");

  // setTimeout(() => (new Image().src = src), 0);

  // $(this)
  //   .find(".gallery__thumbs .gallery__thumb")
  //   .each(function jsgallerythumb() {
  //     const src = $(this).attr("data-src");
  //     // setTimeout(() => (new Image().src = src), 0);
  //     $(this)
  //       .attr("data-srcset")
  //       .split(", ")
  //       .map(i => i.replace(/\s\d{1,5}\w$/, ""))
  //       .forEach(i => {
  //         // setTimeout(() => (new Image().src = i), 0);
  //       });
  //   });
  // });

  $(".js-win-steps .steps__item[data-step]").click(function jsWinSteps() {
    const step = $(this).attr("data-step");

    $(this)
      .addClass("steps__item_active")
      .siblings()
      .removeClass("steps__item_active");

    $(`.steps_content--el[data-step='${step}']`)
      .show()
      .siblings()
      .hide();
  });
});
