import $ from "jquery";
import { isDesktop } from "../../utils/device";
import "./footer.css";

$(() => {
  if (isDesktop()) {
    $("a[data-desktop-href]").each(function() {
      this.setAttribute("href", this.getAttribute("data-desktop-href"));
    });
  }
});
