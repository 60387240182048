import $ from "jquery";
import "./full_form.css";

document.addEventListener("DOMContentLoaded", () => {
  $(document).on(
    "change",
    ".js-radio-gender input[type=radio]",
    function onRadioGender() {
      if ($(this).val() === "female") {
        $(".full_form--female").show();
      } else {
        $(".full_form--female").hide();
      }
    }
  );
});
